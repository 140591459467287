<template>
  <div v-if="reservation">
    <modal-create-reservation-event
      v-if="showCreateReservationEvent"
      :showModal="showCreateReservationEvent"
      :onClickCancel="hideModal"
      :reservation="reservation"
      @getReservationEvent="getReservationEvent"
    />

    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationEventCalendar.Main_Title')
      }}</template>
    </ui-section-header>

    <Message
      v-if="!isLoading && reservationEvent.Id && Object.keys(eventData).length > 0"
      :message="$t('Components.Reservation.ReservationEventCalendar.Message_Event')"
    />
    <hr v-if="!isLoading && reservationEvent.Id && Object.keys(eventData).length > 0">

   <div v-if="!reservationEvent.Id" class="actionBar has-background-white-ter has-padding has-margin-bottom-x2">
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <a
                id="id_button_add"
                @click="setShowCreateReservationEvent"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>{{$t('Components.Reservation.ReservationEventCalendar.Button_CreateEvent')}}</span>
              </a>
            </div>
          </div>
    </div>

    <div v-if="!isLoading && reservationEvent.Id && Object.keys(eventData).length > 0">
      <div class="container">
        <div class="columns is-multiline">
          <!-- Component name -->
          <event-name class="column is-half" />

          <!-- Component image  -->
          <event-image :name="eventData.Name" class="column is-full" />
          <div class="column is-full">
            <div class="columns">
              <div class="column is-two-thirds">
                <div class="columns is-multiline">
                  <!-- Component description -->
                  <event-description class="column is-full" />

                  <!-- Component event program -->
                  <event-program class="column is-full" />
                </div>
              </div>

              <div class="column is-one-third">
                <div class="columns is-multiline">
                  <!-- Component url -->
                  <event-url class="column is-full" />

                  <!-- Component organizer -->
                  <event-organizer class="column is-full" />

                  <!-- Location address -->
                  <div class="column is-full column-locationAddress">
                    <event-location />
                  </div>

                  <!-- Component tags -->
                  <event-tags class="column is-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  

    <Message
          v-if="!isLoading && !reservationEvent.Id"
          :message="$t('Components.Reservation.ReservationEventCalendar.Message_NoEvent')"
       />

    <ui-loader v-if="isLoading" />
  </div> 
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

const EventName = () =>
  import(/* webpackChunkName: "event-detail" */ '@/components/Events/EventName')
const EventImage = () =>
  import(
    /* webpackChunkName: "event-detail" */ '@/components/Events/EventImage'
  )
const EventDescription = () =>
  import(
    /* webpackChunkName: "event-detail" */ '@/components/Events/EventDescription'
  )
const EventLocation = () =>
  import(
    /* webpackChunkName: "event-detail" */ '@/components/Events/EventLocation'
  )
const EventProgram = () =>
  import(
    /* webpackChunkName: "event-detail" */ '@/components/Events/EventProgram'
  )
const EventUrl = () =>
  import(/* webpackChunkName: "event-detail" */ '@/components/Events/EventUrl')
const EventOrganizer = () =>
  import(
    /* webpackChunkName: "event-detail" */ '@/components/Events/EventOrganizer'
  )
const EventTags = () =>
  import(/* webpackChunkName: "event-detail" */ '@/components/Events/EventTags')

const ModalCreateReservationEvent = () => import('@/components/Reservations/ModalCreateReservationEvent')
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    'modal-create-reservation-event': ModalCreateReservationEvent,
    'event-name': EventName,
    'event-image': EventImage,
    'event-description': EventDescription,
    'event-location': EventLocation,
    'event-program': EventProgram,
    'event-url': EventUrl,
    'event-organizer': EventOrganizer,
    'event-tags': EventTags,
    Message
  },

  data() {
    return {
      isLoading: false,
      showCreateReservationEvent: false,
      reservationEvent: {},
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
    ...mapState('eventStore', ['eventData']),
  },

  created() {
   if (!this.reservationEvent.Id) {
      this.getReservationEvent()
   }
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    getReservationEvent() {
      let self = this
      self.isLoading = true
      eventProvider.methods
        .getReservationEvent(self.reservation.Id)
        .then((response) => {
          if (response.status === 200) {
            self.setEventData(response.data)
            self.reservationEvent = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    setShowCreateReservationEvent() {
      this.showCreateReservationEvent = true
    },
    hideModal() {
      this.showCreateReservationEvent = false
    },
  },
}
</script>
