import axios from 'axios'
import store from '../store'
import Vue from 'vue'
import '@/filters/dateTimeFilters.js'

let getLocationEventsToken = null

export default {
  methods: {
    /**
     * GET Get event by ID
     * @param {Number} eventId
     */
    async getEvent(eventId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event/${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )

      return response
    },

    /**
     * GET Get events of a specific location
     * @param {Number} locationId
     * @param {String} q
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @param {Number} showHistory
     */
    async getLocationEvents(
      locationId = 0,
      q = '',
      page = 1,
      itemsPerPage = 25,
      showHistory = false
    ) {
      if (getLocationEventsToken) {
        getLocationEventsToken.cancel()
      }
      getLocationEventsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event/location/${locationId}`,
        {
          cancelToken: getLocationEventsToken.token,

          params: {
            q: q,
            page: page,
            itemsPerPage: itemsPerPage,
            showHistory: showHistory,
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    /**
     * POST Create event
     * @param {Number} locationId
     * @param {String} eventName
     */
    async createEvent(
      locationId = 0,
      eventName = '',
      startDate = Vue.options.filters.dateObjectIsoDateString(new Date()) +
        'T13:00:00',
      endDate = Vue.options.filters.dateObjectIsoDateString(new Date()) +
        'T13:00:00',
      startMinutes = 540,
      endMinutes = 1020,
      tags = '',
      reservationId = 0
    ) {
      let criteria = {
        Name: eventName,
        LocationId: locationId,
        IsActive: false,
        StartDate: startDate,
        StartMinutes: startMinutes,
        EndDate: endDate,
        EndMinutes: endMinutes,
        Description: '',
        Image: '',
        Url: '',
        Tags: tags,
        ProgramItems: [],
        Organizers: [],
        LocationName: '',
        Address: '',
        Postalcode: '',
        City: '',
        State: '',
        Country: '',
        ReservationId: reservationId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    /**
     * PUT save event
     */
    async updateEvent(eventData) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event/${eventData.Id}`,
        eventData,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    /**
     * Delete event
     * @param {Number} eventId
     */
    async deleteEvent(eventId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event/${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async getReservationEvent(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/event/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },


  },
}
